import { LngLatBoundsLike } from 'maplibre-gl';

export interface IMapboxGeocoder {
  type: string;
  query: string[];
  features: IGeocoderFeature[];
  attribution: string;
}

export interface IGeocoderFeature {
  id: string;
  type: string;
  place_type: string[];
  relevance: number;
  properties: IGeocoderProperties;
  text: string;
  address?: string;
  place_name: string;
  bbox: LngLatBoundsLike;
  center: number[];
  geometry: IGeocoderGeometry;
  matching_text?: string;
  matching_place_name?: string;
  context?: IGeocoderContext[];
}

export interface IGeocoderContext {
  id: string;
  wikidata: string;
  short_code: string;
  text: string;
}

export interface IGeocoderGeometry {
  type: string;
  coordinates: number[];
}

export interface IGeocoderProperties {
  wikidata: string;
  short_code: string;
  address?: string;
}

export interface IGeocoderResult {
  name: string;
  countries?: string[];
  id?: string;
  center?: number[];
  isRegion?: boolean;
  nameLine1?: string;
  nameLine2?: string;
  zoom?: number;
  bbox?: LngLatBoundsLike;
  focalPoint?: {
    lat: number;
    lon: number;
  };
}

// Country Codes ISO 3166
// taken from https://gist.github.com/kyranjamie/646386d5edc174e8b549111572897f81
export enum Country {
  UnitedStates = 'US',
  Afghanistan = 'AF',
  AlandIslands = 'AX',
  Albania = 'AL',
  Algeria = 'DZ',
  AmericanSamoa = 'AS',
  Andorra = 'AD',
  Angola = 'AO',
  Anguilla = 'AI',
  Antarctica = 'AQ',
  AntiguaAndBarbuda = 'AG',
  Argentina = 'AR',
  Armenia = 'AM',
  Aruba = 'AW',
  Australia = 'AU',
  Austria = 'AT',
  Azerbaijan = 'AZ',
  Bahamas = 'BS',
  Bahrain = 'BH',
  Bangladesh = 'BD',
  Barbados = 'BB',
  Belarus = 'BY',
  Belgium = 'BE',
  Belize = 'BZ',
  Benin = 'BJ',
  Bermuda = 'BM',
  Bhutan = 'BT',
  Bolivia = 'BO',
  BonaireSintEustatiusSaba = 'BQ',
  BosniaAndHerzegovina = 'BA',
  Botswana = 'BW',
  BouvetIsland = 'BV',
  Brazil = 'BR',
  BritishIndianOceanTerritory = 'IO',
  BruneiDarussalam = 'BN',
  Bulgaria = 'BG',
  BurkinaFaso = 'BF',
  Burundi = 'BI',
  Cambodia = 'KH',
  Cameroon = 'CM',
  Canada = 'CA',
  CapeVerde = 'CV',
  CaymanIslands = 'KY',
  CentralAfricanRepublic = 'CF',
  Chad = 'TD',
  Chile = 'CL',
  China = 'CN',
  ChristmasIsland = 'CX',
  CocosKeelingIslands = 'CC',
  Colombia = 'CO',
  Comoros = 'KM',
  Congo = 'CG',
  CongoDemocraticRepublic = 'CD',
  CookIslands = 'CK',
  CostaRica = 'CR',
  CoteDIvoire = 'CI',
  Croatia = 'HR',
  Cuba = 'CU',
  Curaçao = 'CW',
  Cyprus = 'CY',
  CzechRepublic = 'CZ',
  Denmark = 'DK',
  Djibouti = 'DJ',
  Dominica = 'DM',
  DominicanRepublic = 'DO',
  Ecuador = 'EC',
  Egypt = 'EG',
  ElSalvador = 'SV',
  EquatorialGuinea = 'GQ',
  Eritrea = 'ER',
  Estonia = 'EE',
  Ethiopia = 'ET',
  FalklandIslands = 'FK',
  FaroeIslands = 'FO',
  Fiji = 'FJ',
  Finland = 'FI',
  France = 'FR',
  FrenchGuiana = 'GF',
  FrenchPolynesia = 'PF',
  FrenchSouthernTerritories = 'TF',
  Gabon = 'GA',
  Gambia = 'GM',
  Georgia = 'GE',
  Germany = 'DE',
  Ghana = 'GH',
  Gibraltar = 'GI',
  Greece = 'GR',
  Greenland = 'GL',
  Grenada = 'GD',
  Guadeloupe = 'GP',
  Guam = 'GU',
  Guatemala = 'GT',
  Guernsey = 'GG',
  Guinea = 'GN',
  GuineaBissau = 'GW',
  Guyana = 'GY',
  Haiti = 'HT',
  HeardIslandMcdonaldIslands = 'HM',
  HolySeeVaticanCityState = 'VA',
  Honduras = 'HN',
  HongKong = 'HK',
  Hungary = 'HU',
  Iceland = 'IS',
  India = 'IN',
  Indonesia = 'ID',
  Iran = 'IR',
  Iraq = 'IQ',
  Ireland = 'IE',
  IsleOfMan = 'IM',
  Israel = 'IL',
  Italy = 'IT',
  Jamaica = 'JM',
  Japan = 'JP',
  Jersey = 'JE',
  Jordan = 'JO',
  Kazakhstan = 'KZ',
  Kenya = 'KE',
  Kiribati = 'KI',
  Korea = 'KR',
  KoreaDemocraticPeoplesRepublic = 'KP',
  Kuwait = 'KW',
  Kyrgyzstan = 'KG',
  LaoPeoplesDemocraticRepublic = 'LA',
  Latvia = 'LV',
  Lebanon = 'LB',
  Lesotho = 'LS',
  Liberia = 'LR',
  LibyanArabJamahiriya = 'LY',
  Liechtenstein = 'LI',
  Lithuania = 'LT',
  Luxembourg = 'LU',
  Macao = 'MO',
  Macedonia = 'MK',
  Madagascar = 'MG',
  Malawi = 'MW',
  Malaysia = 'MY',
  Maldives = 'MV',
  Mali = 'ML',
  Malta = 'MT',
  MarshallIslands = 'MH',
  Martinique = 'MQ',
  Mauritania = 'MR',
  Mauritius = 'MU',
  Mayotte = 'YT',
  Mexico = 'MX',
  Micronesia = 'FM',
  Moldova = 'MD',
  Monaco = 'MC',
  Mongolia = 'MN',
  Montenegro = 'ME',
  Montserrat = 'MS',
  Morocco = 'MA',
  Mozambique = 'MZ',
  Myanmar = 'MM',
  Namibia = 'NA',
  Nauru = 'NR',
  Nepal = 'NP',
  Netherlands = 'NL',
  NewCaledonia = 'NC',
  NewZealand = 'NZ',
  Nicaragua = 'NI',
  Niger = 'NE',
  Nigeria = 'NG',
  Niue = 'NU',
  NorfolkIsland = 'NF',
  NorthernMarianaIslands = 'MP',
  Norway = 'NO',
  Oman = 'OM',
  Pakistan = 'PK',
  Palau = 'PW',
  PalestinianTerritory = 'PS',
  Panama = 'PA',
  PapuaNewGuinea = 'PG',
  Paraguay = 'PY',
  Peru = 'PE',
  Philippines = 'PH',
  Pitcairn = 'PN',
  Poland = 'PL',
  Portugal = 'PT',
  PuertoRico = 'PR',
  Qatar = 'QA',
  Reunion = 'RE',
  Romania = 'RO',
  RussianFederation = 'RU',
  Rwanda = 'RW',
  SaintBarthelemy = 'BL',
  SaintHelena = 'SH',
  SaintKittsAndNevis = 'KN',
  SaintLucia = 'LC',
  SaintMartin = 'MF',
  SaintPierreAndMiquelon = 'PM',
  SaintVincentAndGrenadines = 'VC',
  Samoa = 'WS',
  SanMarino = 'SM',
  SaoTomeAndPrincipe = 'ST',
  SaudiArabia = 'SA',
  Senegal = 'SN',
  Serbia = 'RS',
  Seychelles = 'SC',
  SierraLeone = 'SL',
  Singapore = 'SG',
  SintMaarten = 'SX',
  Slovakia = 'SK',
  Slovenia = 'SI',
  SolomonIslands = 'SB',
  Somalia = 'SO',
  SouthAfrica = 'ZA',
  SouthGeorgiaAndSandwichIsl = 'GS',
  SouthSudan = 'SS',
  Spain = 'ES',
  SriLanka = 'LK',
  Sudan = 'SD',
  Suriname = 'SR',
  SvalbardAndJanMayen = 'SJ',
  Swaziland = 'SZ',
  Sweden = 'SE',
  Switzerland = 'CH',
  SyrianArabRepublic = 'SY',
  Taiwan = 'TW',
  Tajikistan = 'TJ',
  Tanzania = 'TZ',
  Thailand = 'TH',
  TimorLeste = 'TL',
  Togo = 'TG',
  Tokelau = 'TK',
  Tonga = 'TO',
  TrinidadAndTobago = 'TT',
  Tunisia = 'TN',
  Turkey = 'TR',
  Turkmenistan = 'TM',
  TurksAndCaicosIslands = 'TC',
  Tuvalu = 'TV',
  Uganda = 'UG',
  Ukraine = 'UA',
  UnitedArabEmirates = 'AE',
  UnitedKingdom = 'GB',
  UnitedStatesOutlyingIslands = 'UM',
  Uruguay = 'UY',
  Uzbekistan = 'UZ',
  Vanuatu = 'VU',
  Venezuela = 'VE',
  VietNam = 'VN',
  VirginIslandsBritish = 'VG',
  VirginIslandsUS = 'VI',
  WallisAndFutuna = 'WF',
  WesternSahara = 'EH',
  Yemen = 'YE',
  Zambia = 'ZM',
  Zimbabwe = 'ZW',
  Unknown = 'Unknown',
}

// generated from https://apps.timwhitlock.info/emoji/tables/iso3166
export type CountryName =
  | 'Andorra'
  | 'United Arab Emirates'
  | 'Afghanistan'
  | 'Antigua and Barbuda'
  | 'Anguilla'
  | 'Albania'
  | 'Armenia'
  | 'Angola'
  | 'Antarctica'
  | 'Argentina'
  | 'American Samoa'
  | 'Austria'
  | 'Australia'
  | 'Aruba'
  | 'Åland Islands'
  | 'Azerbaijan'
  | 'Bosnia and Herzegovina'
  | 'Barbados'
  | 'Bangladesh'
  | 'Belgium'
  | 'Burkina Faso'
  | 'Bulgaria'
  | 'Bahrain'
  | 'Burundi'
  | 'Benin'
  | 'Saint Barthélemy'
  | 'Bermuda'
  | 'Brunei Darussalam'
  | 'Bolivia'
  | 'Bonaire, Sint Eustatius and Saba'
  | 'Brazil'
  | 'Bahamas'
  | 'Bhutan'
  | 'Bouvet Island'
  | 'Botswana'
  | 'Belarus'
  | 'Belize'
  | 'Canada'
  | 'Cocos (Keeling) Islands'
  | 'Congo'
  | 'Central African Republic'
  | 'Congo Democratic Republic'
  | 'Switzerland'
  | "Côte D'Ivoire"
  | 'Cook Islands'
  | 'Chile'
  | 'Cameroon'
  | 'China'
  | 'Colombia'
  | 'Costa Rica'
  | 'Cuba'
  | 'Cape Verde'
  | 'Curaçao'
  | 'Christmas Island'
  | 'Cyprus'
  | 'Czech Republic'
  | 'Germany'
  | 'Djibouti'
  | 'Denmark'
  | 'Dominica'
  | 'Dominican Republic'
  | 'Algeria'
  | 'Ecuador'
  | 'Estonia'
  | 'Egypt'
  | 'Western Sahara'
  | 'Eritrea'
  | 'Spain'
  | 'Ethiopia'
  | 'Finland'
  | 'Fiji'
  | 'Falkland Islands (Malvinas)'
  | 'Micronesia'
  | 'Faroe Islands'
  | 'France'
  | 'Gabon'
  | 'United Kingdom'
  | 'Grenada'
  | 'Georgia'
  | 'French Guiana'
  | 'Guernsey'
  | 'Ghana'
  | 'Gibraltar'
  | 'Greenland'
  | 'Gambia'
  | 'Guinea'
  | 'Guadeloupe'
  | 'Equatorial Guinea'
  | 'Greece'
  | 'South Georgia'
  | 'Guatemala'
  | 'Guam'
  | 'Guinea-Bissau'
  | 'Guyana'
  | 'Hong Kong'
  | 'Heard Island and Mcdonald Islands'
  | 'Honduras'
  | 'Croatia'
  | 'Haiti'
  | 'Hungary'
  | 'Indonesia'
  | 'Ireland'
  | 'Israel'
  | 'Isle of Man'
  | 'India'
  | 'British Indian Ocean Territory'
  | 'Iraq'
  | 'Iran'
  | 'Iceland'
  | 'Italy'
  | 'Jersey'
  | 'Jamaica'
  | 'Jordan'
  | 'Japan'
  | 'Kenya'
  | 'Kyrgyzstan'
  | 'Cambodia'
  | 'Kiribati'
  | 'Comoros'
  | 'Saint Kitts and Nevis'
  | 'North Korea'
  | 'South Korea'
  | 'Kuwait'
  | 'Cayman Islands'
  | 'Kazakhstan'
  | "Lao People's Democratic Republic"
  | 'Lebanon'
  | 'Saint Lucia'
  | 'Liechtenstein'
  | 'Sri Lanka'
  | 'Liberia'
  | 'Lesotho'
  | 'Lithuania'
  | 'Luxembourg'
  | 'Latvia'
  | 'Libya'
  | 'Morocco'
  | 'Monaco'
  | 'Moldova'
  | 'Montenegro'
  | 'Saint Martin (French Part)'
  | 'Madagascar'
  | 'Marshall Islands'
  | 'Macedonia'
  | 'Mali'
  | 'Myanmar'
  | 'Mongolia'
  | 'Macao'
  | 'Northern Mariana Islands'
  | 'Martinique'
  | 'Mauritania'
  | 'Montserrat'
  | 'Malta'
  | 'Mauritius'
  | 'Maldives'
  | 'Malawi'
  | 'Mexico'
  | 'Malaysia'
  | 'Mozambique'
  | 'Namibia'
  | 'New Caledonia'
  | 'Niger'
  | 'Norfolk Island'
  | 'Nigeria'
  | 'Nicaragua'
  | 'Netherlands'
  | 'Norway'
  | 'Nepal'
  | 'Nauru'
  | 'Niue'
  | 'New Zealand'
  | 'Oman'
  | 'Panama'
  | 'Peru'
  | 'French Polynesia'
  | 'Papua New Guinea'
  | 'Philippines'
  | 'Pakistan'
  | 'Poland'
  | 'Saint Pierre and Miquelon'
  | 'Pitcairn'
  | 'Puerto Rico'
  | 'Palestinian Territory'
  | 'Portugal'
  | 'Palau'
  | 'Paraguay'
  | 'Qatar'
  | 'Réunion'
  | 'Romania'
  | 'Serbia'
  | 'Russia'
  | 'Rwanda'
  | 'Saudi Arabia'
  | 'Solomon Islands'
  | 'Seychelles'
  | 'Sudan'
  | 'Sweden'
  | 'Singapore'
  | 'Saint Helena, Ascension and Tristan Da Cunha'
  | 'Slovenia'
  | 'Svalbard and Jan Mayen'
  | 'Slovakia'
  | 'Sierra Leone'
  | 'San Marino'
  | 'Senegal'
  | 'Somalia'
  | 'Suriname'
  | 'South Sudan'
  | 'Sao Tome and Principe'
  | 'El Salvador'
  | 'Sint Maarten (Dutch Part)'
  | 'Syrian Arab Republic'
  | 'Swaziland'
  | 'Turks and Caicos Islands'
  | 'Chad'
  | 'French Southern Territories'
  | 'Togo'
  | 'Thailand'
  | 'Tajikistan'
  | 'Tokelau'
  | 'Timor-Leste'
  | 'Turkmenistan'
  | 'Tunisia'
  | 'Tonga'
  | 'Turkey'
  | 'Trinidad and Tobago'
  | 'Tuvalu'
  | 'Taiwan'
  | 'Tanzania'
  | 'Ukraine'
  | 'Uganda'
  | 'United States Minor Outlying Islands'
  | 'United States'
  | 'Uruguay'
  | 'Uzbekistan'
  | 'Vatican City'
  | 'Saint Vincent and The Grenadines'
  | 'Venezuela'
  | 'Virgin Islands, British'
  | 'Virgin Islands, U.S.'
  | 'Viet Nam'
  | 'Vanuatu'
  | 'Wallis and Futuna'
  | 'Samoa'
  | 'Yemen'
  | 'Mayotte'
  | 'South Africa'
  | 'Zambia'
  | 'Zimbabwe';

// generated from https://apps.timwhitlock.info/emoji/tables/iso3166
export enum CountryISOCode {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

// generated from https://apps.timwhitlock.info/emoji/tables/iso3166
export enum ISOCountryFlag {
  AD = '🇦🇩',
  AE = '🇦🇪',
  AF = '🇦🇫',
  AG = '🇦🇬',
  AI = '🇦🇮',
  AL = '🇦🇱',
  AM = '🇦🇲',
  AO = '🇦🇴',
  AQ = '🇦🇶',
  AR = '🇦🇷',
  AS = '🇦🇸',
  AT = '🇦🇹',
  AU = '🇦🇺',
  AW = '🇦🇼',
  AX = '🇦🇽',
  AZ = '🇦🇿',
  BA = '🇧🇦',
  BB = '🇧🇧',
  BD = '🇧🇩',
  BE = '🇧🇪',
  BF = '🇧🇫',
  BG = '🇧🇬',
  BH = '🇧🇭',
  BI = '🇧🇮',
  BJ = '🇧🇯',
  BL = '🇧🇱',
  BM = '🇧🇲',
  BN = '🇧🇳',
  BO = '🇧🇴',
  BQ = '🇧🇶',
  BR = '🇧🇷',
  BS = '🇧🇸',
  BT = '🇧🇹',
  BV = '🇧🇻',
  BW = '🇧🇼',
  BY = '🇧🇾',
  BZ = '🇧🇿',
  CA = '🇨🇦',
  CC = '🇨🇨',
  CD = '🇨🇩',
  CF = '🇨🇫',
  CG = '🇨🇬',
  CH = '🇨🇭',
  CI = '🇨🇮',
  CK = '🇨🇰',
  CL = '🇨🇱',
  CM = '🇨🇲',
  CN = '🇨🇳',
  CO = '🇨🇴',
  CR = '🇨🇷',
  CU = '🇨🇺',
  CV = '🇨🇻',
  CW = '🇨🇼',
  CX = '🇨🇽',
  CY = '🇨🇾',
  CZ = '🇨🇿',
  DE = '🇩🇪',
  DJ = '🇩🇯',
  DK = '🇩🇰',
  DM = '🇩🇲',
  DO = '🇩🇴',
  DZ = '🇩🇿',
  EC = '🇪🇨',
  EE = '🇪🇪',
  EG = '🇪🇬',
  EH = '🇪🇭',
  ER = '🇪🇷',
  ES = '🇪🇸',
  ET = '🇪🇹',
  FI = '🇫🇮',
  FJ = '🇫🇯',
  FK = '🇫🇰',
  FM = '🇫🇲',
  FO = '🇫🇴',
  FR = '🇫🇷',
  GA = '🇬🇦',
  GB = '🇬🇧',
  GD = '🇬🇩',
  GE = '🇬🇪',
  GF = '🇬🇫',
  GG = '🇬🇬',
  GH = '🇬🇭',
  GI = '🇬🇮',
  GL = '🇬🇱',
  GM = '🇬🇲',
  GN = '🇬🇳',
  GP = '🇬🇵',
  GQ = '🇬🇶',
  GR = '🇬🇷',
  GS = '🇬🇸',
  GT = '🇬🇹',
  GU = '🇬🇺',
  GW = '🇬🇼',
  GY = '🇬🇾',
  HK = '🇭🇰',
  HM = '🇭🇲',
  HN = '🇭🇳',
  HR = '🇭🇷',
  HT = '🇭🇹',
  HU = '🇭🇺',
  ID = '🇮🇩',
  IE = '🇮🇪',
  IL = '🇮🇱',
  IM = '🇮🇲',
  IN = '🇮🇳',
  IO = '🇮🇴',
  IQ = '🇮🇶',
  IR = '🇮🇷',
  IS = '🇮🇸',
  IT = '🇮🇹',
  JE = '🇯🇪',
  JM = '🇯🇲',
  JO = '🇯🇴',
  JP = '🇯🇵',
  KE = '🇰🇪',
  KG = '🇰🇬',
  KH = '🇰🇭',
  KI = '🇰🇮',
  KM = '🇰🇲',
  KN = '🇰🇳',
  KP = '🇰🇵',
  KR = '🇰🇷',
  KW = '🇰🇼',
  KY = '🇰🇾',
  KZ = '🇰🇿',
  LA = '🇱🇦',
  LB = '🇱🇧',
  LC = '🇱🇨',
  LI = '🇱🇮',
  LK = '🇱🇰',
  LR = '🇱🇷',
  LS = '🇱🇸',
  LT = '🇱🇹',
  LU = '🇱🇺',
  LV = '🇱🇻',
  LY = '🇱🇾',
  MA = '🇲🇦',
  MC = '🇲🇨',
  MD = '🇲🇩',
  ME = '🇲🇪',
  MF = '🇲🇫',
  MG = '🇲🇬',
  MH = '🇲🇭',
  MK = '🇲🇰',
  ML = '🇲🇱',
  MM = '🇲🇲',
  MN = '🇲🇳',
  MO = '🇲🇴',
  MP = '🇲🇵',
  MQ = '🇲🇶',
  MR = '🇲🇷',
  MS = '🇲🇸',
  MT = '🇲🇹',
  MU = '🇲🇺',
  MV = '🇲🇻',
  MW = '🇲🇼',
  MX = '🇲🇽',
  MY = '🇲🇾',
  MZ = '🇲🇿',
  NA = '🇳🇦',
  NC = '🇳🇨',
  NE = '🇳🇪',
  NF = '🇳🇫',
  NG = '🇳🇬',
  NI = '🇳🇮',
  NL = '🇳🇱',
  NO = '🇳🇴',
  NP = '🇳🇵',
  NR = '🇳🇷',
  NU = '🇳🇺',
  NZ = '🇳🇿',
  OM = '🇴🇲',
  PA = '🇵🇦',
  PE = '🇵🇪',
  PF = '🇵🇫',
  PG = '🇵🇬',
  PH = '🇵🇭',
  PK = '🇵🇰',
  PL = '🇵🇱',
  PM = '🇵🇲',
  PN = '🇵🇳',
  PR = '🇵🇷',
  PS = '🇵🇸',
  PT = '🇵🇹',
  PW = '🇵🇼',
  PY = '🇵🇾',
  QA = '🇶🇦',
  RE = '🇷🇪',
  RO = '🇷🇴',
  RS = '🇷🇸',
  RU = '🇷🇺',
  RW = '🇷🇼',
  SA = '🇸🇦',
  SB = '🇸🇧',
  SC = '🇸🇨',
  SD = '🇸🇩',
  SE = '🇸🇪',
  SG = '🇸🇬',
  SH = '🇸🇭',
  SI = '🇸🇮',
  SJ = '🇸🇯',
  SK = '🇸🇰',
  SL = '🇸🇱',
  SM = '🇸🇲',
  SN = '🇸🇳',
  SO = '🇸🇴',
  SR = '🇸🇷',
  SS = '🇸🇸',
  ST = '🇸🇹',
  SV = '🇸🇻',
  SX = '🇸🇽',
  SY = '🇸🇾',
  SZ = '🇸🇿',
  TC = '🇹🇨',
  TD = '🇹🇩',
  TF = '🇹🇫',
  TG = '🇹🇬',
  TH = '🇹🇭',
  TJ = '🇹🇯',
  TK = '🇹🇰',
  TL = '🇹🇱',
  TM = '🇹🇲',
  TN = '🇹🇳',
  TO = '🇹🇴',
  TR = '🇹🇷',
  TT = '🇹🇹',
  TV = '🇹🇻',
  TW = '🇹🇼',
  TZ = '🇹🇿',
  UA = '🇺🇦',
  UG = '🇺🇬',
  UM = '🇺🇲',
  US = '🇺🇸',
  UY = '🇺🇾',
  UZ = '🇺🇿',
  VA = '🇻🇦',
  VC = '🇻🇨',
  VE = '🇻🇪',
  VG = '🇻🇬',
  VI = '🇻🇮',
  VN = '🇻🇳',
  VU = '🇻🇺',
  WF = '🇼🇫',
  WS = '🇼🇸',
  YE = '🇾🇪',
  YT = '🇾🇹',
  ZA = '🇿🇦',
  ZM = '🇿🇲',
  ZW = '🇿🇼',
}

export const ISOCodeToISOFlag: Record<CountryISOCode, ISOCountryFlag> = {
  AD: ISOCountryFlag.AD,
  AE: ISOCountryFlag.AE,
  AF: ISOCountryFlag.AF,
  AG: ISOCountryFlag.AG,
  AI: ISOCountryFlag.AI,
  AL: ISOCountryFlag.AL,
  AM: ISOCountryFlag.AM,
  AO: ISOCountryFlag.AO,
  AQ: ISOCountryFlag.AQ,
  AR: ISOCountryFlag.AR,
  AS: ISOCountryFlag.AS,
  AT: ISOCountryFlag.AT,
  AU: ISOCountryFlag.AU,
  AW: ISOCountryFlag.AW,
  AX: ISOCountryFlag.AX,
  AZ: ISOCountryFlag.AZ,
  BA: ISOCountryFlag.BA,
  BB: ISOCountryFlag.BB,
  BD: ISOCountryFlag.BD,
  BE: ISOCountryFlag.BE,
  BF: ISOCountryFlag.BF,
  BG: ISOCountryFlag.BG,
  BH: ISOCountryFlag.BH,
  BI: ISOCountryFlag.BI,
  BJ: ISOCountryFlag.BJ,
  BL: ISOCountryFlag.BL,
  BM: ISOCountryFlag.BM,
  BN: ISOCountryFlag.BN,
  BO: ISOCountryFlag.BO,
  BQ: ISOCountryFlag.BQ,
  BR: ISOCountryFlag.BR,
  BS: ISOCountryFlag.BS,
  BT: ISOCountryFlag.BT,
  BV: ISOCountryFlag.BV,
  BW: ISOCountryFlag.BW,
  BY: ISOCountryFlag.BY,
  BZ: ISOCountryFlag.BZ,
  CA: ISOCountryFlag.CA,
  CC: ISOCountryFlag.CC,
  CD: ISOCountryFlag.CD,
  CF: ISOCountryFlag.CF,
  CG: ISOCountryFlag.CG,
  CH: ISOCountryFlag.CH,
  CI: ISOCountryFlag.CI,
  CK: ISOCountryFlag.CK,
  CL: ISOCountryFlag.CL,
  CM: ISOCountryFlag.CM,
  CN: ISOCountryFlag.CN,
  CO: ISOCountryFlag.CO,
  CR: ISOCountryFlag.CR,
  CU: ISOCountryFlag.CU,
  CV: ISOCountryFlag.CV,
  CW: ISOCountryFlag.CW,
  CX: ISOCountryFlag.CX,
  CY: ISOCountryFlag.CY,
  CZ: ISOCountryFlag.CZ,
  DE: ISOCountryFlag.DE,
  DJ: ISOCountryFlag.DJ,
  DK: ISOCountryFlag.DK,
  DM: ISOCountryFlag.DM,
  DO: ISOCountryFlag.DO,
  DZ: ISOCountryFlag.DZ,
  EC: ISOCountryFlag.EC,
  EE: ISOCountryFlag.EE,
  EG: ISOCountryFlag.EG,
  EH: ISOCountryFlag.EH,
  ER: ISOCountryFlag.ER,
  ES: ISOCountryFlag.ES,
  ET: ISOCountryFlag.ET,
  FI: ISOCountryFlag.FI,
  FJ: ISOCountryFlag.FJ,
  FK: ISOCountryFlag.FK,
  FM: ISOCountryFlag.FM,
  FO: ISOCountryFlag.FO,
  FR: ISOCountryFlag.FR,
  GA: ISOCountryFlag.GA,
  GB: ISOCountryFlag.GB,
  GD: ISOCountryFlag.GD,
  GE: ISOCountryFlag.GE,
  GF: ISOCountryFlag.GF,
  GG: ISOCountryFlag.GG,
  GH: ISOCountryFlag.GH,
  GI: ISOCountryFlag.GI,
  GL: ISOCountryFlag.GL,
  GM: ISOCountryFlag.GM,
  GN: ISOCountryFlag.GN,
  GP: ISOCountryFlag.GP,
  GQ: ISOCountryFlag.GQ,
  GR: ISOCountryFlag.GR,
  GS: ISOCountryFlag.GS,
  GT: ISOCountryFlag.GT,
  GU: ISOCountryFlag.GU,
  GW: ISOCountryFlag.GW,
  GY: ISOCountryFlag.GY,
  HK: ISOCountryFlag.HK,
  HM: ISOCountryFlag.HM,
  HN: ISOCountryFlag.HN,
  HR: ISOCountryFlag.HR,
  HT: ISOCountryFlag.HT,
  HU: ISOCountryFlag.HU,
  ID: ISOCountryFlag.ID,
  IE: ISOCountryFlag.IE,
  IL: ISOCountryFlag.IL,
  IM: ISOCountryFlag.IM,
  IN: ISOCountryFlag.IN,
  IO: ISOCountryFlag.IO,
  IQ: ISOCountryFlag.IQ,
  IR: ISOCountryFlag.IR,
  IS: ISOCountryFlag.IS,
  IT: ISOCountryFlag.IT,
  JE: ISOCountryFlag.JE,
  JM: ISOCountryFlag.JM,
  JO: ISOCountryFlag.JO,
  JP: ISOCountryFlag.JP,
  KE: ISOCountryFlag.KE,
  KG: ISOCountryFlag.KG,
  KH: ISOCountryFlag.KH,
  KI: ISOCountryFlag.KI,
  KM: ISOCountryFlag.KM,
  KN: ISOCountryFlag.KN,
  KP: ISOCountryFlag.KP,
  KR: ISOCountryFlag.KR,
  KW: ISOCountryFlag.KW,
  KY: ISOCountryFlag.KY,
  KZ: ISOCountryFlag.KZ,
  LA: ISOCountryFlag.LA,
  LB: ISOCountryFlag.LB,
  LC: ISOCountryFlag.LC,
  LI: ISOCountryFlag.LI,
  LK: ISOCountryFlag.LK,
  LR: ISOCountryFlag.LR,
  LS: ISOCountryFlag.LS,
  LT: ISOCountryFlag.LT,
  LU: ISOCountryFlag.LU,
  LV: ISOCountryFlag.LV,
  LY: ISOCountryFlag.LY,
  MA: ISOCountryFlag.MA,
  MC: ISOCountryFlag.MC,
  MD: ISOCountryFlag.MD,
  ME: ISOCountryFlag.ME,
  MF: ISOCountryFlag.MF,
  MG: ISOCountryFlag.MG,
  MH: ISOCountryFlag.MH,
  MK: ISOCountryFlag.MK,
  ML: ISOCountryFlag.ML,
  MM: ISOCountryFlag.MM,
  MN: ISOCountryFlag.MN,
  MO: ISOCountryFlag.MO,
  MP: ISOCountryFlag.MP,
  MQ: ISOCountryFlag.MQ,
  MR: ISOCountryFlag.MR,
  MS: ISOCountryFlag.MS,
  MT: ISOCountryFlag.MT,
  MU: ISOCountryFlag.MU,
  MV: ISOCountryFlag.MV,
  MW: ISOCountryFlag.MW,
  MX: ISOCountryFlag.MX,
  MY: ISOCountryFlag.MY,
  MZ: ISOCountryFlag.MZ,
  NA: ISOCountryFlag.NA,
  NC: ISOCountryFlag.NC,
  NE: ISOCountryFlag.NE,
  NF: ISOCountryFlag.NF,
  NG: ISOCountryFlag.NG,
  NI: ISOCountryFlag.NI,
  NL: ISOCountryFlag.NL,
  NO: ISOCountryFlag.NO,
  NP: ISOCountryFlag.NP,
  NR: ISOCountryFlag.NR,
  NU: ISOCountryFlag.NU,
  NZ: ISOCountryFlag.NZ,
  OM: ISOCountryFlag.OM,
  PA: ISOCountryFlag.PA,
  PE: ISOCountryFlag.PE,
  PF: ISOCountryFlag.PF,
  PG: ISOCountryFlag.PG,
  PH: ISOCountryFlag.PH,
  PK: ISOCountryFlag.PK,
  PL: ISOCountryFlag.PL,
  PM: ISOCountryFlag.PM,
  PN: ISOCountryFlag.PN,
  PR: ISOCountryFlag.PR,
  PS: ISOCountryFlag.PS,
  PT: ISOCountryFlag.PT,
  PW: ISOCountryFlag.PW,
  PY: ISOCountryFlag.PY,
  QA: ISOCountryFlag.QA,
  RE: ISOCountryFlag.RE,
  RO: ISOCountryFlag.RO,
  RS: ISOCountryFlag.RS,
  RU: ISOCountryFlag.RU,
  RW: ISOCountryFlag.RW,
  SA: ISOCountryFlag.SA,
  SB: ISOCountryFlag.SB,
  SC: ISOCountryFlag.SC,
  SD: ISOCountryFlag.SD,
  SE: ISOCountryFlag.SE,
  SG: ISOCountryFlag.SG,
  SH: ISOCountryFlag.SH,
  SI: ISOCountryFlag.SI,
  SJ: ISOCountryFlag.SJ,
  SK: ISOCountryFlag.SK,
  SL: ISOCountryFlag.SL,
  SM: ISOCountryFlag.SM,
  SN: ISOCountryFlag.SN,
  SO: ISOCountryFlag.SO,
  SR: ISOCountryFlag.SR,
  SS: ISOCountryFlag.SS,
  ST: ISOCountryFlag.ST,
  SV: ISOCountryFlag.SV,
  SX: ISOCountryFlag.SX,
  SY: ISOCountryFlag.SY,
  SZ: ISOCountryFlag.SZ,
  TC: ISOCountryFlag.TC,
  TD: ISOCountryFlag.TD,
  TF: ISOCountryFlag.TF,
  TG: ISOCountryFlag.TG,
  TH: ISOCountryFlag.TH,
  TJ: ISOCountryFlag.TJ,
  TK: ISOCountryFlag.TK,
  TL: ISOCountryFlag.TL,
  TM: ISOCountryFlag.TM,
  TN: ISOCountryFlag.TN,
  TO: ISOCountryFlag.TO,
  TR: ISOCountryFlag.TR,
  TT: ISOCountryFlag.TT,
  TV: ISOCountryFlag.TV,
  TW: ISOCountryFlag.TW,
  TZ: ISOCountryFlag.TZ,
  UA: ISOCountryFlag.UA,
  UG: ISOCountryFlag.UG,
  UM: ISOCountryFlag.UM,
  US: ISOCountryFlag.US,
  UY: ISOCountryFlag.UY,
  UZ: ISOCountryFlag.UZ,
  VA: ISOCountryFlag.VA,
  VC: ISOCountryFlag.VC,
  VE: ISOCountryFlag.VE,
  VG: ISOCountryFlag.VG,
  VI: ISOCountryFlag.VI,
  VN: ISOCountryFlag.VN,
  VU: ISOCountryFlag.VU,
  WF: ISOCountryFlag.WF,
  WS: ISOCountryFlag.WS,
  YE: ISOCountryFlag.YE,
  YT: ISOCountryFlag.YT,
  ZA: ISOCountryFlag.ZA,
  ZM: ISOCountryFlag.ZM,
  ZW: ISOCountryFlag.ZW,
};

export const CountryNameToISOCode: Record<CountryName, CountryISOCode> = {
  'United States': CountryISOCode.US,
  Andorra: CountryISOCode.AD,
  'United Arab Emirates': CountryISOCode.AE,
  Afghanistan: CountryISOCode.AF,
  'Antigua and Barbuda': CountryISOCode.AG,
  Anguilla: CountryISOCode.AI,
  Albania: CountryISOCode.AL,
  Armenia: CountryISOCode.AM,
  Angola: CountryISOCode.AO,
  Antarctica: CountryISOCode.AQ,
  Argentina: CountryISOCode.AR,
  'American Samoa': CountryISOCode.AS,
  Austria: CountryISOCode.AT,
  Australia: CountryISOCode.AU,
  Aruba: CountryISOCode.AW,
  'Åland Islands': CountryISOCode.AX,
  Azerbaijan: CountryISOCode.AZ,
  'Bosnia and Herzegovina': CountryISOCode.BA,
  Barbados: CountryISOCode.BB,
  Bangladesh: CountryISOCode.BD,
  Belgium: CountryISOCode.BE,
  'Burkina Faso': CountryISOCode.BF,
  Bulgaria: CountryISOCode.BG,
  Bahrain: CountryISOCode.BH,
  Burundi: CountryISOCode.BI,
  Benin: CountryISOCode.BJ,
  'Saint Barthélemy': CountryISOCode.BL,
  Bermuda: CountryISOCode.BM,
  'Brunei Darussalam': CountryISOCode.BN,
  Bolivia: CountryISOCode.BO,
  'Bonaire, Sint Eustatius and Saba': CountryISOCode.BQ,
  Brazil: CountryISOCode.BR,
  Bahamas: CountryISOCode.BS,
  Bhutan: CountryISOCode.BT,
  'Bouvet Island': CountryISOCode.BV,
  Botswana: CountryISOCode.BW,
  Belarus: CountryISOCode.BY,
  Belize: CountryISOCode.BZ,
  Canada: CountryISOCode.CA,
  'Cocos (Keeling) Islands': CountryISOCode.CC,
  Congo: CountryISOCode.CD,
  'Central African Republic': CountryISOCode.CF,
  'Congo Democratic Republic': CountryISOCode.CG,
  Switzerland: CountryISOCode.CH,
  "Côte D'Ivoire": CountryISOCode.CI,
  'Cook Islands': CountryISOCode.CK,
  Chile: CountryISOCode.CL,
  Cameroon: CountryISOCode.CM,
  China: CountryISOCode.CN,
  Colombia: CountryISOCode.CO,
  'Costa Rica': CountryISOCode.CR,
  Cuba: CountryISOCode.CU,
  'Cape Verde': CountryISOCode.CV,
  Curaçao: CountryISOCode.CW,
  'Christmas Island': CountryISOCode.CX,
  Cyprus: CountryISOCode.CY,
  'Czech Republic': CountryISOCode.CZ,
  Germany: CountryISOCode.DE,
  Djibouti: CountryISOCode.DJ,
  Denmark: CountryISOCode.DK,
  Dominica: CountryISOCode.DM,
  'Dominican Republic': CountryISOCode.DO,
  Algeria: CountryISOCode.DZ,
  Ecuador: CountryISOCode.EC,
  Estonia: CountryISOCode.EE,
  Egypt: CountryISOCode.EG,
  'Western Sahara': CountryISOCode.EH,
  Eritrea: CountryISOCode.ER,
  Spain: CountryISOCode.ES,
  Ethiopia: CountryISOCode.ET,
  Finland: CountryISOCode.FI,
  Fiji: CountryISOCode.FJ,
  'Falkland Islands (Malvinas)': CountryISOCode.FK,
  Micronesia: CountryISOCode.FM,
  'Faroe Islands': CountryISOCode.FO,
  France: CountryISOCode.FR,
  Gabon: CountryISOCode.GA,
  'United Kingdom': CountryISOCode.GB,
  Grenada: CountryISOCode.GD,
  Georgia: CountryISOCode.GE,
  'French Guiana': CountryISOCode.GF,
  Guernsey: CountryISOCode.GG,
  Ghana: CountryISOCode.GH,
  Gibraltar: CountryISOCode.GI,
  Greenland: CountryISOCode.GL,
  Gambia: CountryISOCode.GM,
  Guinea: CountryISOCode.GN,
  Guadeloupe: CountryISOCode.GP,
  'Equatorial Guinea': CountryISOCode.GQ,
  Greece: CountryISOCode.GR,
  'South Georgia': CountryISOCode.GS,
  Guatemala: CountryISOCode.GT,
  Guam: CountryISOCode.GU,
  'Guinea-Bissau': CountryISOCode.GW,
  Guyana: CountryISOCode.GY,
  'Hong Kong': CountryISOCode.HK,
  'Heard Island and Mcdonald Islands': CountryISOCode.HM,
  Honduras: CountryISOCode.HN,
  Croatia: CountryISOCode.HR,
  Haiti: CountryISOCode.HT,
  Hungary: CountryISOCode.HU,
  Indonesia: CountryISOCode.ID,
  Ireland: CountryISOCode.IE,
  Israel: CountryISOCode.IL,
  'Isle of Man': CountryISOCode.IM,
  India: CountryISOCode.IN,
  'British Indian Ocean Territory': CountryISOCode.IO,
  Iraq: CountryISOCode.IQ,
  Iran: CountryISOCode.IR,
  Iceland: CountryISOCode.IS,
  Italy: CountryISOCode.IT,
  Jersey: CountryISOCode.JE,
  Jamaica: CountryISOCode.JM,
  Jordan: CountryISOCode.JO,
  Japan: CountryISOCode.JP,
  Kenya: CountryISOCode.KE,
  Kyrgyzstan: CountryISOCode.KG,
  Cambodia: CountryISOCode.KH,
  Kiribati: CountryISOCode.KI,
  Comoros: CountryISOCode.KM,
  'Saint Kitts and Nevis': CountryISOCode.KN,
  'North Korea': CountryISOCode.KP,
  'South Korea': CountryISOCode.KR,
  Kuwait: CountryISOCode.KW,
  'Cayman Islands': CountryISOCode.KY,
  Kazakhstan: CountryISOCode.KZ,
  "Lao People's Democratic Republic": CountryISOCode.LA,
  Lebanon: CountryISOCode.LB,
  'Saint Lucia': CountryISOCode.LC,
  Liechtenstein: CountryISOCode.LI,
  'Sri Lanka': CountryISOCode.LK,
  Liberia: CountryISOCode.LR,
  Lesotho: CountryISOCode.LS,
  Lithuania: CountryISOCode.LT,
  Luxembourg: CountryISOCode.LU,
  Latvia: CountryISOCode.LV,
  Libya: CountryISOCode.LY,
  Morocco: CountryISOCode.MA,
  Monaco: CountryISOCode.MC,
  Moldova: CountryISOCode.MD,
  Montenegro: CountryISOCode.ME,
  'Saint Martin (French Part)': CountryISOCode.MF,
  Madagascar: CountryISOCode.MG,
  'Marshall Islands': CountryISOCode.MH,
  Macedonia: CountryISOCode.MK,
  Mali: CountryISOCode.ML,
  Myanmar: CountryISOCode.MM,
  Mongolia: CountryISOCode.MN,
  Macao: CountryISOCode.MO,
  'Northern Mariana Islands': CountryISOCode.MP,
  Martinique: CountryISOCode.MQ,
  Mauritania: CountryISOCode.MR,
  Montserrat: CountryISOCode.MS,
  Malta: CountryISOCode.MT,
  Mauritius: CountryISOCode.MU,
  Maldives: CountryISOCode.MV,
  Malawi: CountryISOCode.MW,
  Mexico: CountryISOCode.MX,
  Malaysia: CountryISOCode.MY,
  Mozambique: CountryISOCode.MZ,
  Namibia: CountryISOCode.NA,
  'New Caledonia': CountryISOCode.NC,
  Niger: CountryISOCode.NE,
  'Norfolk Island': CountryISOCode.NF,
  Nigeria: CountryISOCode.NG,
  Nicaragua: CountryISOCode.NI,
  Netherlands: CountryISOCode.NL,
  Norway: CountryISOCode.NO,
  Nepal: CountryISOCode.NP,
  Nauru: CountryISOCode.NR,
  Niue: CountryISOCode.NU,
  'New Zealand': CountryISOCode.NZ,
  Oman: CountryISOCode.OM,
  Panama: CountryISOCode.PA,
  Peru: CountryISOCode.PE,
  'French Polynesia': CountryISOCode.PF,
  'Papua New Guinea': CountryISOCode.PG,
  Philippines: CountryISOCode.PH,
  Pakistan: CountryISOCode.PK,
  Poland: CountryISOCode.PL,
  'Saint Pierre and Miquelon': CountryISOCode.PM,
  Pitcairn: CountryISOCode.PN,
  'Puerto Rico': CountryISOCode.PR,
  'Palestinian Territory': CountryISOCode.PS,
  Portugal: CountryISOCode.PT,
  Palau: CountryISOCode.PW,
  Paraguay: CountryISOCode.PY,
  Qatar: CountryISOCode.QA,
  Réunion: CountryISOCode.RE,
  Romania: CountryISOCode.RO,
  Serbia: CountryISOCode.RS,
  Russia: CountryISOCode.RU,
  Rwanda: CountryISOCode.RW,
  'Saudi Arabia': CountryISOCode.SA,
  'Solomon Islands': CountryISOCode.SB,
  Seychelles: CountryISOCode.SC,
  Sudan: CountryISOCode.SD,
  Sweden: CountryISOCode.SE,
  Singapore: CountryISOCode.SG,
  'Saint Helena, Ascension and Tristan Da Cunha': CountryISOCode.SH,
  Slovenia: CountryISOCode.SI,
  'Svalbard and Jan Mayen': CountryISOCode.SJ,
  Slovakia: CountryISOCode.SK,
  'Sierra Leone': CountryISOCode.SL,
  'San Marino': CountryISOCode.SM,
  Senegal: CountryISOCode.SN,
  Somalia: CountryISOCode.SO,
  Suriname: CountryISOCode.SR,
  'South Sudan': CountryISOCode.SS,
  'Sao Tome and Principe': CountryISOCode.ST,
  'El Salvador': CountryISOCode.SV,
  'Sint Maarten (Dutch Part)': CountryISOCode.SX,
  'Syrian Arab Republic': CountryISOCode.SY,
  Swaziland: CountryISOCode.SZ,
  'Turks and Caicos Islands': CountryISOCode.TC,
  Chad: CountryISOCode.TD,
  'French Southern Territories': CountryISOCode.TF,
  Togo: CountryISOCode.TG,
  Thailand: CountryISOCode.TH,
  Tajikistan: CountryISOCode.TJ,
  Tokelau: CountryISOCode.TK,
  'Timor-Leste': CountryISOCode.TL,
  Turkmenistan: CountryISOCode.TM,
  Tunisia: CountryISOCode.TN,
  Tonga: CountryISOCode.TO,
  Turkey: CountryISOCode.TR,
  'Trinidad and Tobago': CountryISOCode.TT,
  Tuvalu: CountryISOCode.TV,
  Taiwan: CountryISOCode.TW,
  Tanzania: CountryISOCode.TZ,
  Ukraine: CountryISOCode.UA,
  Uganda: CountryISOCode.UG,
  'United States Minor Outlying Islands': CountryISOCode.UM,
  Uruguay: CountryISOCode.UY,
  Uzbekistan: CountryISOCode.UZ,
  'Vatican City': CountryISOCode.VA,
  'Saint Vincent and The Grenadines': CountryISOCode.VC,
  Venezuela: CountryISOCode.VE,
  'Virgin Islands, British': CountryISOCode.VG,
  'Virgin Islands, U.S.': CountryISOCode.VI,
  'Viet Nam': CountryISOCode.VN,
  Vanuatu: CountryISOCode.VU,
  'Wallis and Futuna': CountryISOCode.WF,
  Samoa: CountryISOCode.WS,
  Yemen: CountryISOCode.YE,
  Mayotte: CountryISOCode.YT,
  'South Africa': CountryISOCode.ZA,
  Zambia: CountryISOCode.ZM,
  Zimbabwe: CountryISOCode.ZW,
};

export interface ICountryData {
  coordinates: [number, number]; // lon, lat
  zoom: number;
}

export const countryData: { [key: string]: ICountryData } = {
  AF: { coordinates: [67.709953, 33.93911], zoom: 5.5 },
  AL: { coordinates: [20.168331, 41.153332], zoom: 7.5 },
  DZ: { coordinates: [1.659626, 28.033886], zoom: 5.5 },
  AS: { coordinates: [-170.132217, -14.270972], zoom: 9.5 },
  AD: { coordinates: [1.601554, 42.546245], zoom: 9.5 },
  AO: { coordinates: [17.873887, -11.202692], zoom: 5.5 },
  AG: { coordinates: [-61.796428, 17.060816], zoom: 10.5 },
  AR: { coordinates: [-63.616671, -38.416097], zoom: 4.5 },
  AM: { coordinates: [45.038189, 40.069099], zoom: 7.5 },
  AU: { coordinates: [133.775136, -25.274398], zoom: 3.5 },
  AT: { coordinates: [14.550072, 47.516231], zoom: 7.5 },
  AZ: { coordinates: [47.576927, 40.143105], zoom: 7.5 },
  BS: { coordinates: [-77.39628, 25.03428], zoom: 8.5 },
  BH: { coordinates: [50.637772, 25.930414], zoom: 9.5 },
  BD: { coordinates: [90.356331, 23.684994], zoom: 7.5 },
  BB: { coordinates: [-59.543198, 13.193887], zoom: 10.5 },
  BY: { coordinates: [27.953389, 53.709807], zoom: 5.5 },
  BE: { coordinates: [4.469936, 50.503887], zoom: 7.5 },
  BZ: { coordinates: [-88.49765, 17.189877], zoom: 7.5 },
  BJ: { coordinates: [2.315834, 9.30769], zoom: 6.5 },
  BM: { coordinates: [-64.7505, 32.3078], zoom: 9.5 },
  BT: { coordinates: [90.433601, 27.514162], zoom: 7.5 },
  BO: { coordinates: [-63.588653, -16.290154], zoom: 5.5 },
  BA: { coordinates: [17.679076, 43.915886], zoom: 7.5 },
  BW: { coordinates: [24.684866, -22.328474], zoom: 5.5 },
  BR: { coordinates: [-51.92528, -14.235004], zoom: 4.5 },
  BN: { coordinates: [114.727669, 4.535277], zoom: 7.5 },
  BG: { coordinates: [25.48583, 42.733883], zoom: 7.5 },
  BF: { coordinates: [-1.561593, 12.238333], zoom: 6.5 },
  BI: { coordinates: [29.918886, -3.373056], zoom: 7.5 },
  KH: { coordinates: [104.990963, 12.565679], zoom: 7.5 },
  CM: { coordinates: [12.354722, 7.369722], zoom: 6.5 },
  CA: { coordinates: [-106.346771, 56.130366], zoom: 3.5 },
  CV: { coordinates: [-24.013197, 16.002082], zoom: 7.5 },
  CF: { coordinates: [20.939444, 6.611111], zoom: 6.5 },
  TD: { coordinates: [18.732207, 15.454166], zoom: 6.5 },
  CL: { coordinates: [-71.542969, -35.675147], zoom: 4.5 },
  CN: { coordinates: [104.195397, 35.86166], zoom: 4.5 },
  CO: { coordinates: [-74.297333, 4.570868], zoom: 5.5 },
  KM: { coordinates: [43.872219, -11.875001], zoom: 8.5 },
  CG: { coordinates: [15.827659, -0.228021], zoom: 6.5 },
  CR: { coordinates: [-83.753428, 9.748917], zoom: 7.5 },
  HR: { coordinates: [15.2, 45.1], zoom: 7.5 },
  CU: { coordinates: [-77.781167, 21.521757], zoom: 7.5 },
  CY: { coordinates: [33.429859, 35.126413], zoom: 8.5 },
  CZ: { coordinates: [15.472962, 49.817492], zoom: 7.5 },
  DK: { coordinates: [9.501785, 56.26392], zoom: 6.5 },
  DJ: { coordinates: [42.590275, 11.825138], zoom: 7.5 },
  DM: { coordinates: [-61.370976, 15.414999], zoom: 9.5 },
  DO: { coordinates: [-70.162651, 18.735693], zoom: 7.5 },
  EC: { coordinates: [-78.183406, -1.831239], zoom: 6.5 },
  EG: { coordinates: [30.802498, 26.820553], zoom: 5.5 },
  SV: { coordinates: [-88.89653, 13.794185], zoom: 7.5 },
  GQ: { coordinates: [10.267895, 1.650801], zoom: 7.5 },
  ER: { coordinates: [39.782334, 15.179384], zoom: 7.5 },
  EE: { coordinates: [25.013607, 58.595272], zoom: 6.5 },
  ET: { coordinates: [39.782334, 9.145], zoom: 6.5 },
  FJ: { coordinates: [179.414413, -16.578193], zoom: 7.5 },
  FI: { coordinates: [25.748151, 61.92411], zoom: 5.5 },
  FR: { coordinates: [2.213749, 46.603354], zoom: 5.5 },
  GA: { coordinates: [11.609444, -0.803689], zoom: 6.5 },
  GM: { coordinates: [-15.310139, 13.443182], zoom: 8.5 },
  GE: { coordinates: [43.356892, 42.315407], zoom: 7.5 },
  DE: { coordinates: [10.451526, 51.165691], zoom: 5.5 },
  GH: { coordinates: [-1.023194, 7.946527], zoom: 6.5 },
  GR: { coordinates: [21.824312, 39.074208], zoom: 6.5 },
  GD: { coordinates: [-61.679, 12.1165], zoom: 9.5 },
  GT: { coordinates: [-90.230759, 15.783471], zoom: 7.5 },
  GN: { coordinates: [-9.696645, 9.945587], zoom: 7.5 },
  GW: { coordinates: [-15.180413, 11.803749], zoom: 7.5 },
  GY: { coordinates: [-58.93018, 4.860416], zoom: 6.5 },
  HT: { coordinates: [-72.285215, 18.971187], zoom: 7.5 },
  HN: { coordinates: [-86.241905, 15.199999], zoom: 6.5 },
  HU: { coordinates: [19.503304, 47.162494], zoom: 7.5 },
  IS: { coordinates: [-19.020835, 64.963051], zoom: 5.5 },
  IN: { coordinates: [78.96288, 20.593684], zoom: 4.5 },
  ID: { coordinates: [113.921327, -0.789275], zoom: 4.5 },
  IR: { coordinates: [53.688046, 32.427908], zoom: 5.5 },
  IQ: { coordinates: [43.679291, 33.223191], zoom: 6.5 },
  IE: { coordinates: [-8.24389, 53.41291], zoom: 6.5 },
  IL: { coordinates: [34.851612, 31.046051], zoom: 7.5 },
  IT: { coordinates: [12.56738, 41.87194], zoom: 5.5 },
  CI: { coordinates: [-5.54708, 7.539989], zoom: 6.5 },
  JM: { coordinates: [-77.297508, 18.109581], zoom: 7.5 },
  JP: { coordinates: [138.252924, 36.204824], zoom: 5.5 },
  JO: { coordinates: [36.238414, 30.585164], zoom: 7.5 },
  KZ: { coordinates: [66.923684, 48.019573], zoom: 4.5 },
  KE: { coordinates: [37.906193, -0.023559], zoom: 6.5 },
  KI: { coordinates: [-168.734039, -3.370417], zoom: 8.5 },
  KW: { coordinates: [47.481766, 29.31166], zoom: 7.5 },
  KG: { coordinates: [74.766098, 41.20438], zoom: 7.5 },
  LA: { coordinates: [102.495496, 19.85627], zoom: 7.5 },
  LV: { coordinates: [24.603189, 56.879635], zoom: 6.5 },
  LB: { coordinates: [35.862285, 33.854721], zoom: 7.5 },
  LS: { coordinates: [28.233608, -29.609988], zoom: 7.5 },
  LR: { coordinates: [-9.429499, 6.428055], zoom: 7.5 },
  LY: { coordinates: [17.228331, 26.3351], zoom: 5.5 },
  LI: { coordinates: [9.555373, 47.166], zoom: 8.5 },
  LT: { coordinates: [23.881275, 55.169438], zoom: 6.5 },
  LU: { coordinates: [6.129583, 49.815273], zoom: 8.5 },
  MG: { coordinates: [46.869107, -18.766947], zoom: 6.5 },
  MW: { coordinates: [34.301525, -13.254308], zoom: 6.5 },
  MY: { coordinates: [101.975766, 4.210484], zoom: 6.5 },
  MV: { coordinates: [73.22068, 3.202778], zoom: 8.5 },
  ML: { coordinates: [-3.996166, 17.570692], zoom: 5.5 },
  MT: { coordinates: [14.375416, 35.937496], zoom: 9.5 },
  MH: { coordinates: [171.184478, 7.131474], zoom: 8.5 },
  MR: { coordinates: [-10.940835, 21.00789], zoom: 5.5 },
  MU: { coordinates: [57.552152, -20.348404], zoom: 8.5 },
  MX: { coordinates: [-102.552784, 23.634501], zoom: 4.5 },
  FM: { coordinates: [150.550812, 7.425554], zoom: 7.5 },
  MD: { coordinates: [28.369885, 47.411631], zoom: 7.5 },
  MC: { coordinates: [7.412841, 43.750298], zoom: 9.5 },
  MN: { coordinates: [103.846656, 46.862496], zoom: 5.5 },
  ME: { coordinates: [19.37439, 42.708678], zoom: 7.5 },
  MA: { coordinates: [-7.09262, 31.791702], zoom: 6.5 },
  MZ: { coordinates: [35.529562, -18.665695], zoom: 5.5 },
  MM: { coordinates: [95.956223, 21.913965], zoom: 6.5 },
  NA: { coordinates: [18.49041, -22.95764], zoom: 5.5 },
  NR: { coordinates: [166.931503, -0.522778], zoom: 8.5 },
  NP: { coordinates: [84.124008, 28.394857], zoom: 7.5 },
  NL: { coordinates: [5.291266, 52.132633], zoom: 7.5 },
  NZ: { coordinates: [174.885971, -40.900557], zoom: 5.5 },
  NI: { coordinates: [-85.207229, 12.865416], zoom: 6.5 },
  NE: { coordinates: [8.081666, 17.607789], zoom: 6.5 },
  NG: { coordinates: [8.675277, 9.081999], zoom: 5.5 },
  KP: { coordinates: [127.510093, 40.339852], zoom: 5.5 },
  MK: { coordinates: [21.745275, 41.608635], zoom: 7.5 },
  NO: { coordinates: [8.468946, 60.472024], zoom: 5.5 },
  OM: { coordinates: [55.923255, 21.512583], zoom: 7.5 },
  PK: { coordinates: [69.345116, 30.375321], zoom: 5.5 },
  PW: { coordinates: [134.58252, 7.51498], zoom: 8.5 },
  PA: { coordinates: [-80.782127, 8.537981], zoom: 7.5 },
  PG: { coordinates: [143.95555, -6.314993], zoom: 6.5 },
  PY: { coordinates: [-58.443832, -23.442503], zoom: 6.5 },
  PE: { coordinates: [-75.015152, -9.189967], zoom: 5.5 },
  PH: { coordinates: [121.774017, 12.879721], zoom: 6.5 },
  PL: { coordinates: [19.145136, 51.919438], zoom: 6.5 },
  PT: { coordinates: [-8.224454, 39.399872], zoom: 6.5 },
  QA: { coordinates: [51.183884, 25.354826], zoom: 8.5 },
  RO: { coordinates: [24.96676, 45.943161], zoom: 6.5 },
  RU: { coordinates: [105.318756, 61.52401], zoom: 3.5 },
  RW: { coordinates: [29.873888, -1.940278], zoom: 7.5 },
  KN: { coordinates: [-62.782998, 17.357822], zoom: 9.5 },
  LC: { coordinates: [-60.978893, 13.909444], zoom: 9.5 },
  VC: { coordinates: [-61.287228, 12.984305], zoom: 9.5 },
  WS: { coordinates: [-172.104629, -13.759029], zoom: 8.5 },
  SM: { coordinates: [12.457777, 43.94236], zoom: 9.5 },
  ST: { coordinates: [6.613081, 0.18636], zoom: 8.5 },
  SA: { coordinates: [45.079162, 23.885942], zoom: 5.5 },
  SN: { coordinates: [-14.452362, 14.497401], zoom: 6.5 },
  RS: { coordinates: [21.005859, 44.016521], zoom: 6.5 },
  SC: { coordinates: [55.491977, -4.679574], zoom: 8.5 },
  SL: { coordinates: [-11.779889, 8.460555], zoom: 7.5 },
  SG: { coordinates: [103.819836, 1.352083], zoom: 9.5 },
  SK: { coordinates: [19.699024, 48.669026], zoom: 7.5 },
  SI: { coordinates: [14.995463, 46.151241], zoom: 7.5 },
  SB: { coordinates: [160.156194, -9.64571], zoom: 7.5 },
  SO: { coordinates: [46.199616, 5.152149], zoom: 6.5 },
  ZA: { coordinates: [22.937506, -30.559482], zoom: 5.5 },
  KR: { coordinates: [127.766922, 35.907757], zoom: 6.5 },
  ES: { coordinates: [-3.74922, 40.463667], zoom: 5.5 },
  LK: { coordinates: [80.771797, 7.873054], zoom: 7.5 },
  SD: { coordinates: [30.217636, 12.862807], zoom: 6.5 },
  SR: { coordinates: [-56.027783, 3.919305], zoom: 6.5 },
  SZ: { coordinates: [31.465866, -26.522503], zoom: 7.5 },
  SE: { coordinates: [18.643501, 60.128161], zoom: 5.5 },
  CH: { coordinates: [8.227512, 46.818188], zoom: 7.5 },
  SY: { coordinates: [38.996815, 34.802075], zoom: 7.5 },
  TW: { coordinates: [120.960515, 23.69781], zoom: 7.5 },
  TJ: { coordinates: [71.276093, 38.861034], zoom: 6.5 },
  TZ: { coordinates: [34.888822, -6.369028], zoom: 5.5 },
  TH: { coordinates: [100.992541, 15.870032], zoom: 6.5 },
  TL: { coordinates: [125.727539, -8.874217], zoom: 7.5 },
  TG: { coordinates: [0.824782, 8.619543], zoom: 6.5 },
  TO: { coordinates: [-175.198242, -21.178986], zoom: 8.5 },
  TT: { coordinates: [-61.222503, 10.691803], zoom: 8.5 },
  TN: { coordinates: [9.537499, 33.886917], zoom: 6.5 },
  TR: { coordinates: [35.243322, 38.963745], zoom: 5.5 },
  TM: { coordinates: [59.556278, 38.969719], zoom: 6.5 },
  TV: { coordinates: [179.194, -7.1095], zoom: 8.5 },
  UG: { coordinates: [32.290275, 1.373333], zoom: 6.5 },
  UA: { coordinates: [31.16558, 48.379433], zoom: 5.5 },
  AE: { coordinates: [53.847818, 23.424076], zoom: 7.5 },
  GB: { coordinates: [-3.435973, 55.378051], zoom: 5.5 },
  US: { coordinates: [-95.712891, 37.09024], zoom: 3.5 },
  UY: { coordinates: [-55.765835, -32.522779], zoom: 6.5 },
  UZ: { coordinates: [64.585262, 41.377491], zoom: 6.5 },
  VU: { coordinates: [166.959158, -15.376706], zoom: 7.5 },
  VE: { coordinates: [-66.58973, 6.42375], zoom: 6.5 },
  VN: { coordinates: [108.277199, 14.058324], zoom: 6.5 },
  YE: { coordinates: [48.516388, 15.552727], zoom: 6.5 },
  ZM: { coordinates: [27.849332, -13.133897], zoom: 5.5 },
  ZW: { coordinates: [29.154857, -19.015438], zoom: 6.5 },
};
