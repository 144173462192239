import React, { useCallback, useState, useRef, useEffect } from 'react';
import Router from 'next/router';
import { LngLatBoundsLike } from 'maplibre-gl';
import CloseIcon from '@/components/ui/icons/CloseIcon';
import SearchIcon from '@/components/ui/icons/SearchIcon';
import { sanitizeRegion } from '@/utils/explorer';
import { IGeocoderResult, IMapboxGeocoder } from '@/types/geocode';
import { forwardGeocoding } from '@/api/map';
import { COVERAGE_ROOT } from '@/utils/explorer';
import { DEFAULT_ZOOM, extractGeocoderLocation } from '@/utils/geolocation';
import { Input } from '@/components/shadcn/input';

// matches only valid coordinates
const COORDINATE_REGEX_STRING =
  '^[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?),\\s*[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)';
const COORDINATE_REGEX = RegExp(COORDINATE_REGEX_STRING);

const LIMIT = 5;

const KEY = {
  ENTER: 13,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
};

let debounceTimeout: NodeJS.Timeout | null = null;

const testForCoordinates = (query: string) => {
  const isValid = COORDINATE_REGEX.test(query.trim());

  if (!isValid) {
    return { hasValidCoordinates: isValid, query };
  }

  const tokens = query.trim().split(',');

  return {
    hasValidCoordinates: isValid,
    longitude: Number(tokens[0]),
    latitude: Number(tokens[1]),
  };
};

interface Props {
  proximity?: [number?, number?]; // [longitude, latitude]
  onSelected: (coords: {
    lat: number;
    lon: number;
    zoom: number;
    bbox?: LngLatBoundsLike;
  }) => void;
  placeholder?: string;
}

const GeoCoder: React.FC<Props> = ({
  onSelected,
  placeholder = 'Search for a location',
  proximity = [],
}) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const timeout = 400;
  const [inputValue, setInputValue] = useState('');
  const [showResults, setShowResults] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [results, setResults] = useState<IGeocoderResult[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  const onChange = useCallback(
    (event: any) => {
      const queryString = event.target.value;
      setInputValue(queryString);
      const { hasValidCoordinates, longitude, latitude } =
        testForCoordinates(queryString);
      if (hasValidCoordinates) {
        setResults([{ center: [latitude, longitude], name: queryString }]);
      } else {
        debounceTimeout && clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(async () => {
          if (queryString) {
            let results: IGeocoderResult[] = [];
            const limit = LIMIT - results.length;
            try {
              const response: IMapboxGeocoder = await forwardGeocoding(
                encodeURI(queryString),
                {
                  limit: limit.toString(),
                  proximity: proximity?.join(','),
                },
              );

              if (response.features) {
                results = response.features.map(extractGeocoderLocation);
              }
              setSelectedIndex(0);
              setShowResults(true);
            } catch (e) {
              console.log(e);
            }
            setResults(results);
          } else {
            setResults([]);
          }
        }, timeout);
      }
    },
    [timeout, setResults, setShowResults, proximity],
  );

  const onFocus = useCallback(() => {
    setIsExpanded(true);
    setShowResults(true);
  }, [setShowResults]);

  const onItemSelected = useCallback(
    (item: IGeocoderResult) => {
      if (item.isRegion) {
        const regionName = sanitizeRegion(item.name);
        Router.push(`${COVERAGE_ROOT}/${regionName}`, undefined, {
          shallow: true,
        });

        const { focalPoint, bbox } = item;
        if (focalPoint) {
          const { lat, lon } = focalPoint;
          onSelected({ lat, lon, bbox, zoom: DEFAULT_ZOOM });
        }
      } else {
        const { center, bbox, zoom = DEFAULT_ZOOM } = item;
        const [lon, lat] = center || [];
        if (lat && lon) {
          onSelected({ lat, lon, zoom, bbox });
        }
      }
      setShowResults(false);
      setInputValue(item.name);
      setShowDelete(true);
    },
    [onSelected],
  );

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      setShowResults(false);
      if (!inputValue) {
        setIsExpanded(false);
      }
    }, 200);
  }, [setIsExpanded, inputValue]);

  const onDelete = useCallback(() => {
    setShowDelete(false);
    setIsExpanded(false);
    setResults([]);
    setInputValue('');
    inputEl?.current?.focus();
  }, []);

  const onKeyDown = useCallback(
    (e: any) => {
      if (!results || results.length === 0) {
        return;
      }
      switch (e.keyCode) {
        case KEY.ARROW_UP:
          setSelectedIndex(
            selectedIndex > 0 ? selectedIndex - 1 : selectedIndex,
          );
          break;
        case KEY.ARROW_DOWN:
          setSelectedIndex(
            selectedIndex < results.length - 1
              ? selectedIndex + 1
              : selectedIndex,
          );
          break;
        case KEY.ENTER:
          if (results[selectedIndex]) {
            onItemSelected(results[selectedIndex]);
          }
          break;
        default:
          break;
      }
    },
    [results, selectedIndex, setSelectedIndex, onItemSelected],
  );

  useEffect(() => {
    if (isExpanded && inputEl?.current) {
      inputEl.current.focus();
    } else if (inputEl?.current) {
      inputEl.current.blur();
    }
  }, [isExpanded]);

  return (
    <>
      <div
        data-expanded={isExpanded}
        onClick={() => {
          setIsExpanded(true);
          inputEl?.current?.focus();
        }}
        className="peer/geocoder relative flex h-[32px] w-full origin-top-right items-center gap-2 rounded border border-neutral-700 bg-neutral-900 py-1 text-neutral-0 data-[expanded=false]:w-[32px] data-[expanded=true]:max-w-[240px] data-[expanded=false]:px-[7px] data-[expanded=true]:px-4 group-data-[type=light]/mainMap:border-neutral-400 group-data-[type=light]/mainMap:bg-neutral-0 group-data-[type=light]/mainMap:text-neutral-900 sm:data-[expanded=true]:max-w-[300px] md:w-[300px] md:data-[expanded=false]:w-[300px] md:data-[expanded=false]:px-4">
        <SearchIcon className="size-4 shrink-0 fill-current" />
        <Input
          className="h-auto w-full rounded-none border-none p-0 leading-none placeholder:text-neutral-700 data-[expanded=true]:w-0 group-data-[type=light]/mainMap:placeholder:text-neutral-400 md:data-[expanded=true]:w-0"
          ref={inputEl}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={handleBlur}
          onKeyDown={onKeyDown}
          value={inputValue}
          placeholder={placeholder}
        />
        {showDelete ? (
          <div
            aria-label="close"
            onClick={onDelete}
            className="text-neutral-0 transition hover:text-azure-500 group-data-[type=light]/mainMap:text-neutral-900 hover:group-data-[type=light]/mainMap:text-azure-500">
            <CloseIcon className="size-3 shrink-0 fill-current" />
          </div>
        ) : null}
      </div>

      {showResults && results.length > 0 ? (
        <ul className="relative top-2 w-full max-w-[240px] overflow-hidden rounded border border-neutral-700 bg-neutral-900 text-neutral-0 group-data-[type=light]/mainMap:border-neutral-400 group-data-[type=light]/mainMap:bg-neutral-0 group-data-[type=light]/mainMap:text-neutral-900 peer-data-[expanded=false]/geocoder:hidden sm:max-w-[300px]">
          {results.map((item, index) => {
            const isActive = selectedIndex === index;
            return (
              <li
                key={index}
                aria-hidden="true"
                data-active={isActive}
                className="px-4 py-2 text-xs leading-normal hover:bg-neutral-800 data-[active=true]:bg-neutral-800 hover:group-data-[type=light]/mainMap:bg-neutral-200 group-data-[type=light]/mainMap:data-[active=true]:bg-neutral-200"
                onClick={() => onItemSelected(item)}>
                <>
                  {item.nameLine1}
                  {item.nameLine2 ? (
                    <div className="text-[10px] text-neutral-600">
                      {item.nameLine2}
                    </div>
                  ) : null}
                </>
              </li>
            );
          })}
        </ul>
      ) : null}
    </>
  );
};

export default GeoCoder;
