import { apiCall } from '@/utils/api';
import { getMapboxAccessToken } from '@/utils/map';
import { buildQueryParamString } from '@/utils/url';
import { Country, ICountryData, countryData } from '@/types/geocode';
import {
  Bounty,
  BountyCollectionType,
  GeoJSONPolygon,
  Region,
  RegionType,
} from '@/types/map';
import { IStats } from '@/types/stats';

export const getPreviewImagesForPoly = async (
  polygon: GeoJSONPolygon,
  sinceDate: string,
  page: number,
  pageSize: number,
): Promise<any> => {
  try {
    const response = await apiCall(
      `${process.env.NEXT_PUBLIC_API}/dashcam/v1/${sinceDate}/preview/polygon?page=${page}&pageSize=${pageSize}`,
      { method: 'POST' },
      { polygon },
    );
    return response?.assets;
  } catch (error: any) {
    return null;
  }
};

export const makeFreshViewJob = async (
  geojson: GeoJSONPolygon,
  startRaw: Date,
): Promise<any> => {
  try {
    const response = await apiCall(
      `${process.env.NEXT_PUBLIC_API}/freshview/make`,
      { method: 'POST' },
      { geojson, startRaw },
    );
    return response;
  } catch (error: any) {
    return JSON.stringify(error);
  }
};

export const getBountyByRegionId = async (
  regionId: string,
): Promise<Bounty | undefined> => {
  try {
    const response = await apiCall(
      `${process.env.NEXT_PUBLIC_API}/regions/bounty/${regionId}`,
      { method: 'GET' },
    );
    return response?.bounty;
  } catch (error: unknown) {
    return undefined;
  }
};

export const getRegion = async (
  regionId: string,
): Promise<Region | undefined> => {
  try {
    const response = await apiCall(
      `${process.env.NEXT_PUBLIC_API}/regions/region/${regionId}`,
      { method: 'GET' },
    );
    return response?.region;
  } catch (error: unknown) {
    return undefined;
  }
};

export const createRegion = async (
  polygon: GeoJSONPolygon,
  name: string,
  type: RegionType,
  country: Country,
): Promise<Region | undefined> => {
  try {
    const response = await apiCall(
      `${process.env.NEXT_PUBLIC_API}/regions/`,
      { method: 'POST' },
      { polygon, name, type, country },
    );
    return response?.region;
  } catch (error: unknown) {
    return undefined;
  }
};

export const disableRegion = async (
  regionId: string,
): Promise<Region | undefined> => {
  try {
    const response = await apiCall(
      `${process.env.NEXT_PUBLIC_API}/regions/disable/${regionId}`,
      { method: 'POST' },
    );
    return response?.region;
  } catch (error: unknown) {
    return undefined;
  }
};

export const activateBounty = async (
  regionId: string,
  type: BountyCollectionType,
): Promise<Bounty | undefined> => {
  try {
    if (regionId) {
      const response = await apiCall(
        `${process.env.NEXT_PUBLIC_API}/regions/bounty/activate/${regionId}`,
        { method: 'POST' },
        { type },
      );
      return response?.bounty;
    } else {
      return undefined;
    }
  } catch (error: unknown) {
    return undefined;
  }
};

export const closeBounty = async (
  bountyId: string,
): Promise<boolean | undefined> => {
  try {
    const response = await apiCall(
      `${process.env.NEXT_PUBLIC_API}/regions/bounty/close/${bountyId}`,
      { method: 'POST' },
    );
    return response?.closed;
  } catch (error: unknown) {
    return undefined;
  }
};

export const getRegionStats = async (
  regionId: string,
): Promise<IStats | undefined> => {
  try {
    const { stats } = await apiCall(
      `${process.env.NEXT_PUBLIC_API}/regions/contributions/${regionId}`,
      { method: 'GET' },
    );
    return stats;
  } catch (error: unknown) {
    return undefined;
  }
};

export const getConsumePolygonApiQuery = (
  polygon: GeoJSONPolygon,
  sinceDate: string,
) => {
  return `curl -X POST \
${process.env.NEXT_PUBLIC_API}/dashcam/v1/${sinceDate}/consume/polygon \
-H "Content-Type: application/json" \
-H "Authorization: Basic <Base64 encoded string of username:apiKey>" \
-d '{ "polygon": ${JSON.stringify(polygon, null, 2)} }'`;
};

export const getRegionData = (regionId: string) => {
  const regionData: any = {
    '60ed4d082fcc38c57ec70f0b': {
      displayName: 'Los Angeles Metro, CA',
      area: 7861.6,
      roads: 72175,
    },
    '60ed4d0f2fcc38c57ec70f14': {
      displayName: 'Riverside Metro, CA',
      area: 44109.4,
      roads: 54724,
    },
    '60ed4d102fcc38c57ec70f17': {
      displayName: 'San Diego Metro, CA',
      area: 6818,
      roads: 26868.2,
    },
    '60ed4d072fcc38c57ec70f0a': {
      displayName: 'Las Vegas Metro, NV',
      area: 12972.8,
      roads: 20370.9,
    },
    '60ed4d0d2fcc38c57ec70f12': {
      displayName: 'Phoenix Metro, AZ',
      area: 23495.5,
      roads: 58444,
    },
    '60ed4d112fcc38c57ec70f19': {
      displayName: 'San Jose Metro, CA',
      area: 4327.4,
      roads: 14928.5,
    },
    '60ed4d102fcc38c57ec70f18': {
      displayName: 'San Francisco Metro, CA',
      area: 4075.3,
      roads: 32180.1,
    },
    '60ed4d0f2fcc38c57ec70f15': {
      displayName: 'Salt Lake City, UT',
      area: 13028.9,
      roads: 13918.1,
    },
    '60ed4d0d2fcc38c57ec70f13': {
      displayName: 'Portland Metro, OR',
      area: 10982.2,
      roads: 37701,
    },
    '60ed4d122fcc38c57ec70f1a': {
      displayName: 'Seattle Metro, WA',
      area: 9640.6,
      roads: 47725.2,
    },
    '60ed4d052fcc38c57ec70f07': {
      displayName: 'Denver Metro, CO',
      area: 13522.9,
      roads: 45434.1,
    },
    '60ed4d022fcc38c57ec70f00': {
      displayName: 'Albuquerque, NM',
      area: 14959.8,
      roads: 29134.8,
    },
    '60ed4d0f2fcc38c57ec70f16': {
      displayName: 'San Antonio Metro, TX',
      area: 11862.3,
      roads: 40896.8,
    },
    '60ed4d062fcc38c57ec70f09': {
      displayName: 'Houston Metro, TX',
      area: 13801.6,
      roads: 79487,
    },
    '60ed4d052fcc38c57ec70f06': {
      displayName: 'Dallas Metro, TX',
      area: 14495.7,
      roads: 97041.3,
    },
    '60ed4d042fcc38c57ec70f02': {
      displayName: 'Austin, MN',
      area: 1145.1,
      roads: 4058,
    },
    '60ed4d052fcc38c57ec70f05': {
      displayName: 'Chicago Metro, IL',
      area: 11755.2,
      roads: 98184.9,
    },
    '60ed4d052fcc38c57ec70f08': {
      displayName: 'Detroit Metro, MI',
      area: 6407.7,
      roads: 58697,
    },
    '60ed4d042fcc38c57ec70f03': {
      displayName: 'Boston Metro, MA',
      area: 5821.6,
      roads: 52953.3,
    },
    '60ed4d0a2fcc38c57ec70f0f': {
      displayName: 'New York Metro, NY',
      area: 11052,
      roads: 126704.1,
    },
    '60ed4d0c2fcc38c57ec70f11': {
      displayName: 'Philadelphia Metro, PA',
      area: 7578.3,
      roads: 66642.8,
    },
    '60ed4d132fcc38c57ec70f1c': {
      displayName: 'Washington Metro, DC',
      area: 10711.7,
      roads: 72832.1,
    },
    '60ed4d042fcc38c57ec70f04': {
      displayName: 'Charlotte Metro, NC',
      area: 9195.3,
      roads: 48471.5,
    },
    '60ed4d032fcc38c57ec70f01': {
      displayName: 'Atlanta Metro, GA',
      area: 14218.6,
      roads: 85295.2,
    },
    '60ed4d082fcc38c57ec70f0d': {
      displayName: 'Nashville Metro, TN',
      area: 9278.8,
      roads: 39562,
    },
    '60ed4d092fcc38c57ec70f0e': {
      displayName: 'New Orleans Metro, LA',
      area: 7330.1,
      roads: 19565.5,
    },
    '60ed4d132fcc38c57ec70f1b': {
      displayName: 'Tampa Metro, FL',
      area: 4215.2,
      roads: 33929.4,
    },
    '60ed4d0b2fcc38c57ec70f10': {
      displayName: 'Orlando Metro, FL',
      area: 6455.9,
      roads: 28446.2,
    },
    '60ed4d082fcc38c57ec70f0c': {
      displayName: 'Miami Metro, FL',
      area: 8723.2,
      roads: 42847.3,
    },
    '60ed5032e88f58cbe5ab6740': {
      displayName: 'Manchester',
      area: 2234.5,
      roads: 16725.7,
    },
    '60edfa4957c1781c31d43825': {
      displayName: 'Bristol',
      area: 176.6,
      roads: 2027.8,
    },
    '614e259af3a7ae0032ab1f3b': {
      displayName: 'London',
      area: 4697.3,
      roads: 35501.3,
    },
    '614e3467116f65002a7dc07f': {
      displayName: 'Kuala Lumpur',
      area: 169.5,
      roads: 4071.7,
    },
    '614cde3cffcd8b0054e6d233': {
      displayName: 'Singapore',
      area: 0,
      roads: 0,
    },
    '60edfb37c6757a1e5e42f415': {
      displayName: 'Palawan',
      area: 9314.8,
      roads: 6806.4,
    },
    '614cffb05121ee004c593593': {
      displayName: 'Manila Metro',
      area: 369.8,
      roads: 9342.6,
    },
  };
  return regionData[regionId];
};

const DEFAULT_BUCKET_NAME = 'network-tiles';

export const getTilesBucket = async (): Promise<string | undefined> => {
  try {
    const { config } = await apiCall(
      `${process.env.NEXT_PUBLIC_API}/config/tilesBucket`,
      {
        method: 'GET',
      },
    );
    return config;
  } catch (error: unknown) {
    return DEFAULT_BUCKET_NAME;
  }
};

export const forwardGeocoding = async (
  query: string,
  options = { limit: '0', proximity: '' },
) => {
  const queryParams = buildQueryParamString({
    access_token: getMapboxAccessToken(),
    ...options,
  });
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json${queryParams}`;
  try {
    return await apiCall(url, { method: 'GET', credentials: 'omit' });
  } catch (error: any) {
    return {};
  }
};

export const getCountryDataByCountryCode = (
  countryCode: string,
): ICountryData | null => {
  return countryData[countryCode.toUpperCase()] || null;
};
