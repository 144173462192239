import { IGeocoderFeature, IGeocoderResult } from '@/types/geocode';

export interface Coordinates {
  coords: {
    latitude: number;
    longitude: number;
  };
}

export const getUserCurrentLocation = (
  successCallback: (lat: number, lng: number) => void,
  errorCallback: () => void,
) => {
  const geoOptions = {
    timeout: 10 * 1000,
    maximumAge: 60 * 1000,
  };

  navigator.geolocation.getCurrentPosition(
    (position: Coordinates) => {
      successCallback(position.coords.latitude, position.coords.longitude);
    },
    errorCallback,
    geoOptions,
  );
};

export const DEFAULT_ZOOM = 11;

export const getZoomLevel = (location: IGeocoderFeature) => {
  switch (location?.place_type?.[0]) {
    case 'poi':
    case 'locality':
    case 'neighborhood':
    case 'address':
      return 16;
    default:
      return DEFAULT_ZOOM;
  }
};

export const extractGeocoderLocation: (
  location: IGeocoderFeature,
) => IGeocoderResult = (location: IGeocoderFeature) => {
  const bbox = location?.bbox;
  const address1 = location?.address;
  const address2 = location?.properties?.address;
  const text = location?.text;
  const place = location?.context?.filter(c => c.id.includes('place'))[0]?.text;
  const region = location?.context?.filter(c => c.id.includes('region'))[0]
    ?.text;
  const country = location?.context?.filter(c => c.id.includes('country'))[0]
    ?.text;
  let nameLine1 = [address1, text, address2].filter(Boolean).join(', ');
  let nameLine2 = [place, region, country].filter(Boolean).join(', ');
  if (!nameLine1) {
    if (nameLine2) {
      nameLine1 = nameLine2;
    } else {
      nameLine1 = location?.place_name;
    }
    nameLine2 = '';
  }
  const name = [nameLine1, nameLine2].filter(Boolean).join(', ');
  const zoom = getZoomLevel(location);
  return {
    ...location,
    nameLine1,
    nameLine2,
    name,
    bbox,
    zoom,
  };
};
