import { SVGProps } from 'react';

const SearchIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={16} height={17} viewBox="0 0 16 17" {...props}>
      <path d="M0 7.11675C0 10.6733 2.88585 13.5532 6.4424 13.5532C7.69833 13.5532 8.85821 13.1843 9.836 12.5501L13.2228 15.944C13.4843 16.2007 13.8303 16.3245 14.1862 16.3245C14.9496 16.3245 15.5107 15.7418 15.5107 14.9871C15.5107 14.6333 15.3803 14.3001 15.1344 14.0413L11.771 10.6719C12.4708 9.66364 12.8778 8.4412 12.8778 7.11675C12.8778 3.56131 9.99783 0.675476 6.4424 0.675476C2.88585 0.675476 0 3.56131 0 7.11675ZM1.90087 7.11675C1.90087 4.60673 3.93125 2.57633 6.4424 2.57633C8.9465 2.57633 10.9828 4.60673 10.9828 7.11675C10.9828 9.62199 8.9465 11.6583 6.4424 11.6583C3.93125 11.6583 1.90087 9.62199 1.90087 7.11675Z" />
    </svg>
  );
};

export default SearchIcon;
